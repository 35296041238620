export const viewportRanges = {
    small: [0, 599],
    medium: [600, 899],
    normal: [900, 1299],
    large: [1300, 5000],
};

export function isViewportInRange(min: number, max: number) {
    return window.innerWidth >= min && window.innerWidth <= max;
}

export function isSmallViewport() {
    return isViewportInRange(viewportRanges.small[0], viewportRanges.small[1]);
}

export function isMediumViewport() {
    return isViewportInRange(viewportRanges.medium[0], viewportRanges.medium[1]);
}

export function isNormalViewport() {
    return isViewportInRange(viewportRanges.normal[0], viewportRanges.normal[1]);
}

export function isLargeViewport() {
    return isViewportInRange(viewportRanges.large[0], viewportRanges.large[1]);
}

export function viewportSize() {
    if (isSmallViewport()) {
        return 'small';
    } else if (isMediumViewport()) {
        return 'medium';
    } else if (isNormalViewport()) {
        return 'normal';
    }
    return 'unknown';
}
