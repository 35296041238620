// ES6 polyfill
import 'babel-polyfill';

// requestAnimationFrame polyfill
import 'requestanimationframe';

// Custom modernizr configuration
import '../libs/modernizr';

// fetch polyfill
import 'whatwg-fetch';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';

// ie11 grid support
import 'polyfill/grid';

// nodelist polyfill (forEach)
import 'polyfill/nodelist';
