/*! modernizr 3.3.1 (Custom Build) | MIT *
 * http://modernizr.com/download/?-cssanimations-csstransforms-csstransforms3d-csstransitions-flexbox-flexboxlegacy-history-touchevents-domprefixes-mq-prefixed-prefixes-setclasses-shiv-testallprops-testprop-teststyles !*/
!(function(e, t, n) {
    function r(e, t) {
        return typeof e === t;
    }
    function o() {
        var e, t, n, o, i, a, s;
        for (var u in x)
            if (x.hasOwnProperty(u)) {
                if (
                    ((e = []),
                    (t = x[u]),
                    t.name &&
                        (e.push(t.name.toLowerCase()),
                        t.options && t.options.aliases && t.options.aliases.length))
                )
                    for (n = 0; n < t.options.aliases.length; n++)
                        e.push(t.options.aliases[n].toLowerCase());
                for (o = r(t.fn, 'function') ? t.fn() : t.fn, i = 0; i < e.length; i++)
                    (a = e[i]),
                        (s = a.split('.')),
                        1 === s.length
                            ? (Modernizr[s[0]] = o)
                            : (!Modernizr[s[0]] ||
                                  Modernizr[s[0]] instanceof Boolean ||
                                  (Modernizr[s[0]] = new Boolean(Modernizr[s[0]])),
                              (Modernizr[s[0]][s[1]] = o)),
                        y.push((o ? '' : 'no-') + s.join('-'));
            }
    }
    function i(e) {
        var t = b.className,
            n = Modernizr._config.classPrefix || '';
        if ((E && (t = t.baseVal), Modernizr._config.enableJSClass)) {
            var r = new RegExp('(^|\\s)' + n + 'no-js(\\s|$)');
            t = t.replace(r, '$1' + n + 'js$2');
        }
        Modernizr._config.enableClasses &&
            ((t += ' ' + n + e.join(' ' + n)), E ? (b.className.baseVal = t) : (b.className = t));
    }
    function a(e) {
        return e
            .replace(/([a-z])-([a-z])/g, function(e, t, n) {
                return t + n.toUpperCase();
            })
            .replace(/^-/, '');
    }
    function s() {
        return 'function' != typeof t.createElement
            ? t.createElement(arguments[0])
            : E
            ? t.createElementNS.call(t, 'http://www.w3.org/2000/svg', arguments[0])
            : t.createElement.apply(t, arguments);
    }
    function u() {
        var e = t.body;
        return e || ((e = s(E ? 'svg' : 'body')), (e.fake = !0)), e;
    }
    function l(e, n, r, o) {
        var i,
            a,
            l,
            f,
            c = 'modernizr',
            d = s('div'),
            p = u();
        if (parseInt(r, 10))
            for (; r--; ) (l = s('div')), (l.id = o ? o[r] : c + (r + 1)), d.appendChild(l);
        return (
            (i = s('style')),
            (i.type = 'text/css'),
            (i.id = 's' + c),
            (p.fake ? p : d).appendChild(i),
            p.appendChild(d),
            i.styleSheet ? (i.styleSheet.cssText = e) : i.appendChild(t.createTextNode(e)),
            (d.id = c),
            p.fake &&
                ((p.style.background = ''),
                (p.style.overflow = 'hidden'),
                (f = b.style.overflow),
                (b.style.overflow = 'hidden'),
                b.appendChild(p)),
            (a = n(d, e)),
            p.fake
                ? (p.parentNode.removeChild(p), (b.style.overflow = f), b.offsetHeight)
                : d.parentNode.removeChild(d),
            !!a
        );
    }
    function f(e, t) {
        return !!~('' + e).indexOf(t);
    }
    function c(e, t) {
        return function() {
            return e.apply(t, arguments);
        };
    }
    function d(e, t, n) {
        var o;
        for (var i in e)
            if (e[i] in t)
                return n === !1 ? e[i] : ((o = t[e[i]]), r(o, 'function') ? c(o, n || t) : o);
        return !1;
    }
    function p(e) {
        return e
            .replace(/([A-Z])/g, function(e, t) {
                return '-' + t.toLowerCase();
            })
            .replace(/^ms-/, '-ms-');
    }
    function m(t, r) {
        var o = t.length;
        if ('CSS' in e && 'supports' in e.CSS) {
            for (; o--; ) if (e.CSS.supports(p(t[o]), r)) return !0;
            return !1;
        }
        if ('CSSSupportsRule' in e) {
            for (var i = []; o--; ) i.push('(' + p(t[o]) + ':' + r + ')');
            return (
                (i = i.join(' or ')),
                l('@supports (' + i + ') { #modernizr { position: absolute; } }', function(e) {
                    return 'absolute' == getComputedStyle(e, null).position;
                })
            );
        }
        return n;
    }
    function h(e, t, o, i) {
        function u() {
            c && (delete O.style, delete O.modElem);
        }
        if (((i = r(i, 'undefined') ? !1 : i), !r(o, 'undefined'))) {
            var l = m(e, o);
            if (!r(l, 'undefined')) return l;
        }
        for (var c, d, p, h, v, g = ['modernizr', 'tspan']; !O.style; )
            (c = !0), (O.modElem = s(g.shift())), (O.style = O.modElem.style);
        for (p = e.length, d = 0; p > d; d++)
            if (((h = e[d]), (v = O.style[h]), f(h, '-') && (h = a(h)), O.style[h] !== n)) {
                if (i || r(o, 'undefined')) return u(), 'pfx' == t ? h : !0;
                try {
                    O.style[h] = o;
                } catch (y) {}
                if (O.style[h] != v) return u(), 'pfx' == t ? h : !0;
            }
        return u(), !1;
    }
    function v(e, t, n, o, i) {
        var a = e.charAt(0).toUpperCase() + e.slice(1),
            s = (e + ' ' + j.join(a + ' ') + a).split(' ');
        return r(t, 'string') || r(t, 'undefined')
            ? h(s, t, o, i)
            : ((s = (e + ' ' + _.join(a + ' ') + a).split(' ')), d(s, t, n));
    }
    function g(e, t, r) {
        return v(e, n, n, t, r);
    }
    var y = [],
        x = [],
        C = {
            _version: '3.3.1',
            _config: { classPrefix: '', enableClasses: !0, enableJSClass: !0, usePrefixes: !0 },
            _q: [],
            on: function(e, t) {
                var n = this;
                setTimeout(function() {
                    t(n[e]);
                }, 0);
            },
            addTest: function(e, t, n) {
                x.push({ name: e, fn: t, options: n });
            },
            addAsyncTest: function(e) {
                x.push({ name: null, fn: e });
            },
        },
        Modernizr = function() {};
    (Modernizr.prototype = C),
        (Modernizr = new Modernizr()),
        Modernizr.addTest('history', function() {
            var t = navigator.userAgent;
            return (-1 === t.indexOf('Android 2.') && -1 === t.indexOf('Android 4.0')) ||
                -1 === t.indexOf('Mobile Safari') ||
                -1 !== t.indexOf('Chrome') ||
                -1 !== t.indexOf('Windows Phone')
                ? e.history && 'pushState' in e.history
                : !1;
        });
    var S = C._config.usePrefixes ? ' -webkit- -moz- -o- -ms- '.split(' ') : ['', ''];
    C._prefixes = S;
    var b = t.documentElement,
        E = 'svg' === b.nodeName.toLowerCase();
    E ||
        !(function(e, t) {
            function n(e, t) {
                var n = e.createElement('p'),
                    r = e.getElementsByTagName('head')[0] || e.documentElement;
                return (
                    (n.innerHTML = 'x<style>' + t + '</style>'),
                    r.insertBefore(n.lastChild, r.firstChild)
                );
            }
            function r() {
                var e = x.elements;
                return 'string' == typeof e ? e.split(' ') : e;
            }
            function o(e, t) {
                var n = x.elements;
                'string' != typeof n && (n = n.join(' ')),
                    'string' != typeof e && (e = e.join(' ')),
                    (x.elements = n + ' ' + e),
                    l(t);
            }
            function i(e) {
                var t = y[e[v]];
                return t || ((t = {}), g++, (e[v] = g), (y[g] = t)), t;
            }
            function a(e, n, r) {
                if ((n || (n = t), c)) return n.createElement(e);
                r || (r = i(n));
                var o;
                return (
                    (o = r.cache[e]
                        ? r.cache[e].cloneNode()
                        : h.test(e)
                        ? (r.cache[e] = r.createElem(e)).cloneNode()
                        : r.createElem(e)),
                    !o.canHaveChildren || m.test(e) || o.tagUrn ? o : r.frag.appendChild(o)
                );
            }
            function s(e, n) {
                if ((e || (e = t), c)) return e.createDocumentFragment();
                n = n || i(e);
                for (var o = n.frag.cloneNode(), a = 0, s = r(), u = s.length; u > a; a++)
                    o.createElement(s[a]);
                return o;
            }
            function u(e, t) {
                t.cache ||
                    ((t.cache = {}),
                    (t.createElem = e.createElement),
                    (t.createFrag = e.createDocumentFragment),
                    (t.frag = t.createFrag())),
                    (e.createElement = function(n) {
                        return x.shivMethods ? a(n, e, t) : t.createElem(n);
                    }),
                    (e.createDocumentFragment = Function(
                        'h,f',
                        'return function(){var n=f.cloneNode(),c=n.createElement;h.shivMethods&&(' +
                            r()
                                .join()
                                .replace(/[\w\-:]+/g, function(e) {
                                    return (
                                        t.createElem(e), t.frag.createElement(e), 'c("' + e + '")'
                                    );
                                }) +
                            ');return n}'
                    )(x, t.frag));
            }
            function l(e) {
                e || (e = t);
                var r = i(e);
                return (
                    !x.shivCSS ||
                        f ||
                        r.hasCSS ||
                        (r.hasCSS = !!n(
                            e,
                            'article,aside,dialog,figcaption,figure,footer,header,hgroup,main,nav,section{display:block}mark{background:#FF0;color:#000}template{display:none}'
                        )),
                    c || u(e, r),
                    e
                );
            }
            var f,
                c,
                d = '3.7.3',
                p = e.html5 || {},
                m = /^<|^(?:button|map|select|textarea|object|iframe|option|optgroup)$/i,
                h = /^(?:a|b|code|div|fieldset|h1|h2|h3|h4|h5|h6|i|label|li|ol|p|q|span|strong|style|table|tbody|td|th|tr|ul)$/i,
                v = '_html5shiv',
                g = 0,
                y = {};
            !(function() {
                try {
                    var e = t.createElement('a');
                    (e.innerHTML = '<xyz></xyz>'),
                        (f = 'hidden' in e),
                        (c =
                            1 == e.childNodes.length ||
                            (function() {
                                t.createElement('a');
                                var e = t.createDocumentFragment();
                                return (
                                    'undefined' == typeof e.cloneNode ||
                                    'undefined' == typeof e.createDocumentFragment ||
                                    'undefined' == typeof e.createElement
                                );
                            })());
                } catch (n) {
                    (f = !0), (c = !0);
                }
            })();
            var x = {
                elements:
                    p.elements ||
                    'abbr article aside audio bdi canvas data datalist details dialog figcaption figure footer header hgroup main mark meter nav output picture progress section summary template time video',
                version: d,
                shivCSS: p.shivCSS !== !1,
                supportsUnknownElements: c,
                shivMethods: p.shivMethods !== !1,
                type: 'default',
                shivDocument: l,
                createElement: a,
                createDocumentFragment: s,
                addElements: o,
            };
            (e.html5 = x),
                l(t),
                'object' == typeof module && module.exports && (module.exports = x);
        })('undefined' != typeof e ? e : this, t);
    var w = 'Moz O ms Webkit',
        _ = C._config.usePrefixes ? w.toLowerCase().split(' ') : [];
    C._domPrefixes = _;
    var T = 'CSS' in e && 'supports' in e.CSS,
        z = 'supportsCSS' in e;
    Modernizr.addTest('supports', T || z);
    var N = (function() {
        var t = e.matchMedia || e.msMatchMedia;
        return t
            ? function(e) {
                  var n = t(e);
                  return (n && n.matches) || !1;
              }
            : function(t) {
                  var n = !1;
                  return (
                      l('@media ' + t + ' { #modernizr { position: absolute; } }', function(t) {
                          n =
                              'absolute' ==
                              (e.getComputedStyle ? e.getComputedStyle(t, null) : t.currentStyle)
                                  .position;
                      }),
                      n
                  );
              };
    })();
    C.mq = N;
    var P = (C.testStyles = l);
    Modernizr.addTest('touchevents', function() {
        var n;
        if ('ontouchstart' in e || (e.DocumentTouch && t instanceof DocumentTouch)) n = !0;
        else {
            var r = [
                '@media (',
                S.join('touch-enabled),('),
                'heartz',
                ')',
                '{#modernizr{top:9px;position:absolute}}',
            ].join('');
            P(r, function(e) {
                n = 9 === e.offsetTop;
            });
        }
        return n;
    });
    var j = C._config.usePrefixes ? w.split(' ') : [];
    C._cssomPrefixes = j;
    var k = function(t) {
        var r,
            o = S.length,
            i = e.CSSRule;
        if ('undefined' == typeof i) return n;
        if (!t) return !1;
        if (((t = t.replace(/^@/, '')), (r = t.replace(/-/g, '_').toUpperCase() + '_RULE'), r in i))
            return '@' + t;
        for (var a = 0; o > a; a++) {
            var s = S[a],
                u = s.toUpperCase() + '_' + r;
            if (u in i) return '@-' + s.toLowerCase() + '-' + t;
        }
        return !1;
    };
    C.atRule = k;
    var M = { elem: s('modernizr') };
    Modernizr._q.push(function() {
        delete M.elem;
    });
    var O = { style: M.elem.style };
    Modernizr._q.unshift(function() {
        delete O.style;
    });
    C.testProp = function(e, t, r) {
        return h([e], n, t, r);
    };
    C.testAllProps = v;
    C.prefixed = function(e, t, n) {
        return 0 === e.indexOf('@')
            ? k(e)
            : (-1 != e.indexOf('-') && (e = a(e)), t ? v(e, t, n) : v(e, 'pfx'));
    };
    (C.testAllProps = g),
        Modernizr.addTest('cssanimations', g('animationName', 'a', !0)),
        Modernizr.addTest('flexbox', g('flexBasis', '1px', !0)),
        Modernizr.addTest('flexboxlegacy', g('boxDirection', 'reverse', !0)),
        Modernizr.addTest('csstransforms', function() {
            return (
                -1 === navigator.userAgent.indexOf('Android 2.') && g('transform', 'scale(1)', !0)
            );
        }),
        Modernizr.addTest('csstransforms3d', function() {
            var e = !!g('perspective', '1px', !0),
                t = Modernizr._config.usePrefixes;
            if (e && (!t || 'webkitPerspective' in b.style)) {
                var n,
                    r = '#modernizr{width:0;height:0}';
                Modernizr.supports
                    ? (n = '@supports (perspective: 1px)')
                    : ((n = '@media (transform-3d)'), t && (n += ',(-webkit-transform-3d)')),
                    (n += '{#modernizr{width:7px;height:18px;margin:0;padding:0;border:0}}'),
                    P(r + n, function(t) {
                        e = 7 === t.offsetWidth && 18 === t.offsetHeight;
                    });
            }
            return e;
        }),
        Modernizr.addTest('csstransitions', g('transition', 'all', !0)),
        o(),
        i(y),
        delete C.addTest,
        delete C.addAsyncTest;
    for (var A = 0; A < Modernizr._q.length; A++) Modernizr._q[A]();
    e.Modernizr = Modernizr;
})(window, document);
