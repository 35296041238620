import { trackLink } from '@99designs/common/utils/platform';
import { viewportSize } from 'common/utils/viewport-detection';

export default function trackGlobalNavLink() {
    const loggedOutLogo = document.querySelector(
        '.site-header--signed-out .site-header__site-logo'
    );
    const loggedOutHeaderLinks = Array.from(
        document.querySelectorAll('.site-header--signed-out .top-level-nav__item__link')
    );
    const footerLinks = Array.from(
        document.querySelectorAll('.site-footer .footer-links-menu__items__item__link')
    );
    const loggedOutOffCanvasLinkList = Array.from(
        document.querySelectorAll('.off-canvas-nav--signed-out .menu__item.menu__item--action-list')
    );
    const offCanvasSections = [
        'home',
        'site-header',
        'design-services',
        'get-a-design',
        'support',
        'resources',
    ];

    if (loggedOutLogo) {
        trackLinkWithLocation(loggedOutLogo, 'site-header');
    }

    loggedOutHeaderLinks.forEach(link => {
        trackLinkWithLocation(link, 'site-header');
    });

    footerLinks.forEach(link => {
        trackLinkWithLocation(link, 'site-footer');
    });

    loggedOutOffCanvasLinkList.forEach((list, index) => {
        let links = Array.from(list.querySelectorAll('.menu__item__action__link'));

        links.forEach(link => {
            let offCanvasSection = offCanvasSections[index] || index;
            trackLinkWithLocation(link, `off-canvas-${offCanvasSection}`);
        });
    });

    function trackLinkWithLocation(element, location) {
        trackLink(element, 'Clicked Global Nav Link', {
            location: location,
            viewport_size: viewportSize(),
        });
    }
}
