import debounce from 'lodash/debounce';

function applyComponentFixes(grid) {
    let currentRow = 1;
    let currentColumn = 1;
    let columns = window.getComputedStyle(grid).msGridColumns.split(' ').length;

    Array.prototype.forEach.call(grid.children, row => {
        if (currentColumn > columns) {
            currentColumn = 1;
            currentRow += 1;
        }

        if (
            row.style['-ms-grid-column'] == currentColumn &&
            row.style['-ms-grid-row'] == currentRow
        ) {
            currentColumn += 1;
            return;
        }

        row.style['-ms-grid-column-span'] = 1;
        row.style['-ms-grid-column'] = currentColumn;
        row.style['-ms-grid-row'] = currentRow;
        row.style['margin'] = '5px';

        currentColumn += 1;
    });
}

const debounceFixes = debounce(applyComponentFixes, 100);

function runPolyfill() {
    Array.prototype.forEach.call(document.querySelectorAll(`.ie11-grid-fix`), debounceFixes);
}

const supportsGrid =
    typeof CSS !== 'undefined' &&
    typeof CSS.supports !== 'undefined' &&
    CSS.supports('display', 'grid');

if (!supportsGrid) {
    window.addEventListener('load', function() {
        window.addEventListener('popstate', runPolyfill);
        document.body.addEventListener('DOMSubtreeModified', runPolyfill);
        runPolyfill();
    });
}
