import './polyfill';
import trackGlobalNavLink from 'marketing/components/GlobalNavEngagement';
import 'lazysizes';

// 99designs global navigation
require(['common/components/globalNav'], createGlobalNav => {
    createGlobalNav.default();
});

document.addEventListener('DOMContentLoaded', () => {
    trackGlobalNavLink();
});
